.mainPage{
    overflow-x: scroll;
    margin: 0 auto;
    padding:  0 80px;
    height: 100vh;
    width: 100vw;
    padding-bottom: 40px;
}

.pageContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap:30px
}

.globalSelectors{
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.globalCallerSelector{
    margin-left: auto;
    width: 350px;
}

.cardHolder{
    display: grid;
    grid-template-columns: repeat( 6, minmax(200px, 1fr) );
    gap: 10px;
    grid-auto-rows: minmax(auto, auto);
    max-height: min-content;
    flex-grow: 0 !important;
    flex-shrink:0;
    margin: 40px 0px;
}

@media only screen and (max-width: 1280px) {
    .cardHolder{
        grid-template-columns: repeat( auto-fill, minmax(200px, 1fr) );
    }
}

.firstPage{
    min-height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
    max-height: max-content;
    align-content: center;
    justify-content: flex-start;
    width: 100%;
}

.twoGraphSideWays {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    width: 100%;
    align-items: center;
}

.secondPage{
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
    margin-bottom: 100px;
}
.wrapper{
    padding: 20px;
    min-height: 100%;
    display: flex;
    /* change flex-direction from column to row will work */
    flex-direction: column;
    box-sizing:border-box;
    flex-basis: 50%;
}

.graphContainer{
    flex: 1 1 50%;
    flex-direction: column;
    display: flex;
    position: relative;
    margin: 30px 0px;
    height: 100%;
}

.graphHolder{
    min-height: 50vh;
    margin: 30px 0px;
    display: flex;
    flex-basis: 100%;
    width: 100%;
}

.graphHolder > div {
    flex: 1;
    width: 100%;
    flex-basis: 100%;
    height: 100%;
}

.tableHolder{
    margin: 30px 0px;
}

