.listLeadsSreenbody {
    /* overflow: auto;  */
    overflow: scroll;
    height: calc(100vh - 55px);
    padding-left: 50px;
    padding-right: 50px;
}

.listLeadsSreenbodyheader {
    padding-top: 25px;
    margin-bottom: 30px;
}

.leadsListingAction{
    width: 2px;
    height: 2px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1.4px solid #797979;
    cursor: pointer;
}
.listLeadsSreenbodyTable {
    max-height: calc(100vh - 178px);
}
.createNewLeadBody {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.createNewLeadBody p{
    font-size: 16px;
    font-weight: 400;
}

.cardComponent {
    height: 250px! important;
    width: 250px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cardComponentInner {
    /* box-shadow: 1px 1px 4px rgba(0,0,0,0.1); */
	display: flex;
	flex-direction: column;
	align-items: center;
    height: 220px! important;
    width: 220px !important;
    margin:auto;
    justify-content: space-around;
	
}

.icon {
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 44px;
    color: white;
    border-radius: 50%;
    
}

.form input{
	display: block;
}

.rolesDisplay {
    /* max-height: 70vh;
    height: fit-content;
    overflow: scroll; */
	margin-top:2rem;
	display: grid;
    justify-content: center;
	grid-template-columns: repeat(auto-fit,250px);
    column-gap: 3rem;
	row-gap: 3rem;
}

.roleItem {
    display: flex;
    align-items: center;
    justify-content: space-around;
	min-width: 280px;
    min-height:150px;
}
.roleButtons {
    width: 80%;
    margin:0 auto;
    display: flex;
    justify-content: space-around;
    
    
}

.permissionCheckboxes {
    
    display: grid !important;
    grid-template-columns: repeat(auto-fit,minmax(120px,max-content));
	column-gap: 2rem;
	row-gap: 2rem;
}
.permissionCheckboxes > *{
    width: 100% !important;
    margin: 0 !important;
}