.mainBody{
    padding: 0px 20px;
}

.addBtnThenCondition{
    float: right;
}

.thenIndividualContainer{
    display: flex;
    justify-content: space-between;
}

.thenConditionContainer{
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-right: 30px;
}

.thenSingleElement{
    display: flex;
    align-items: center;
    gap:30px;
}
