.nineDots{
    
    background-position: center !important;
    width: 35px;
   
    
    border-radius: 50%;
    background-size: 25px !important;
    aspect-ratio: 1/1;
    border: transparent !important;
    margin:0 auto;
    background-repeat: no-repeat !important;
    cursor:pointer;
}
.nineDotsContainer {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    margin-right:1rem;
   
}
.nineDotsContainer:hover{
    background-color: #f5f5f5;
}
.setBg{
    background-color: #f5f5f5 !important;
    }
.container {
    align-items: start;
    max-width:276px;
    box-sizing: border-box ;
    display: grid;
    /* grid-auto-columns: 100px; */
    grid-template-columns: repeat(auto-fit,minmax(85px,1fr));
    column-gap: 0.5rem;
    
}

.col {
    
    height:80px;
    box-sizing: border-box;
    margin: auto;
}

.col:hover {
    background-color: #f5f5f5;
    border-radius: 3px;
   
}
.img{
    display: block;
    margin: auto;
}

.heading {
    margin: 0;
    text-align:center;
    color: black;
	font-size: 16px;
    line-height: 1.2;
	word-spacing: 100vw;
   
}
.content {
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin:auto;
    justify-content: center;
    align-items: center;
}

