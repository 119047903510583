.appHeader {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    padding: 10px 17px;
    display: flex;
    justify-content: space-between;
}

.rightappHeader {
    display: flex;
    gap: 7px;
    align-items: center;
}

.leftappHeader {
    display: flex;
    align-items: center;
    gap: 20px
}

.leftappHeader button {
    display: flex;
    align-items: center;
}

.profilePicContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    /* border: 1px solid black; */
}

.userInfoContainer {
    display: grid;
}

.userInfoContainer span{
    text-align: right;
}

.profileRole {
    color: #DE6834;
    font-size: 13px;
    font-weight: 700 !important;
    font-family: 'Avenir', sans-serif;
    text-transform: uppercase;
}

.profileName {
    color: black;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 120px;
    font-size: 17px;
    font-weight: 400 !important;
    font-family: 'Avenir Book', sans-serif !important;
}
.userInfoOuterContainer{
    display: flex;
    cursor: pointer;
}
.contentContainer {
    display: flex;
    padding:0.4rem 0;
    margin:-12px;
    padding:none;
    width: 130px;
    flex-direction: column;
}
.contentInnerContainer {
    display: flex;
    padding-left:0.5rem;
    cursor: pointer;
    column-gap: 0.4rem;
    height: fit-content;
    width: 100%;
}
.contentInnerContainer p {
    margin:7px 0px;
}
.contentInnerContainer:hover {
   
    background-color: #eee;
}