.mainBody{
    position: fixed;
    width: 70vw;
    height: 650px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 10;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
}

.background{
    position: fixed;
    width: 1001vw;
    height: 1001vh;
    overflow: hidden;
    z-index: 8;
    background-color: black;
    opacity: 30%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.titleHolder{
    background-color: #de7a50;
    color: white;
    overflow: hidden;
    padding: 3px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.buttonCluster{
    display: flex;
    gap: 15px;
}

.subHeading{
    font-weight: 600;
}

.container{
    padding: 0px 30px;
}

.basicRuleDetails{
    display: flex;
    gap: 40px;
    justify-content: space-between;
}

.tabChild{
    height: 380px;
    overflow: scroll;
}

.previewContainer{
    height: 470px;
    overflow-y: scroll;
}
