img.searchImg {
    position: absolute;
    left: 10px;
    top: 6px;
    width: 17px;
    height: 17px;
}

input.search {
    border: 0;
    border-bottom: 1.2px solid #eeeeee !important;
    outline: none;
    width: 200px;
    padding-right: 20px !important;
    height: 30px;
    border-radius: 0;
    padding-left: 25px;
    margin-left: 8px;
    background-color: transparent;
    font-size: 15px;
    font-family: 'Hind', sans-serif;
}

input.search::placeholder {
    font-family: 'Hind', sans-serif;
    font-size: 15px;
    color: #b6b6b6;
    padding-top: 10px;
    padding-bottom: 10px;
}