@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box; 
}

/* scrollbar customize css starts */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb:hover {
  background: #8e8e8e;
}

::-webkit-scrollbar-thumb {
  background: #e5e5e5;
  border-radius: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 20px;
}
/* scrollbar customize css ends */


/* antd table css starts*/
.ant-table-body {
  max-height: calc(100vh - 278px) !important;
}

.ant-table-wrapper .ant-table-pagination-right {
  justify-content: center !important;
}

.ant-table-cell {
  border-inline-end: unset !important;
}

.ant-table-cell::before {
  display: none;
}

.ant-table-cell {
  font-size: 15px;
  font-family: 'Mulish', sans-serif;
}

th.ant-table-cell {
  font-weight: bold !important;
}

/* .ant-table-column, .ant-table-column-sort {
  background: unset !important;
} */

/* antd table css ends*/

.timelineTitleBox {
  gap: 10px;
  display: flex;
  margin-left: 45px;
  padding: 12px 30px;
  border-radius: 6px;
  width: fit-content;
  align-items: center;
  cursor: pointer;
  background-color: #fff !important;
  box-shadow: rgb(0 0 0 / 5%) 0px 0px 0px 1px;
}

.timeLineActive {
  background-color: #FFEEE5 !important;
  box-shadow: 0px 5px 10px #00000029 !important;
}

.timelineTitleBox.timeLineActive .timelineTitle {
  color: #de6834;
}

.timelineTitle {
  font-size: 15px;
  color: #333333;
  font-weight: 500;
  font-family: 'Hind', sans-serif;
}

.timelineSubTitle{
  display: inline-block;
  margin-left:10px;
}

.groupAndLeadTitle {
  display: block;
  color: rgb(222, 104, 52) !important;
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 15px;
  font-family: 'mulish', sans-serif;
  margin: 4px 0;
}

.modalForm label {
	color: #de6834 !important;
	font-family: 'Hind', sans-serif;
  font-size: 16px !important;
}

/* calender css starts*/
.ant-picker-body{
  padding: 0px 18px !important;
}

.ant-picker-dropdown .ant-picker-content th {
  height: 30px !important;
}
.ant-picker-dropdown .ant-picker-cell {
  padding: 2px 0 !important;
}
.ant-picker-dropdown .ant-picker-time-panel .ant-picker-content{
  height: 170px !important;
}
.ant-picker-dropdown .ant-picker-ranges{
  margin-block-start: 0;
}

/* search filters container on group report report page css */
.searchFilterContainer {
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.searchFilterContainer label {
  color: #8D8D8D !important;
  margin-bottom: 2px !important;
  font-family: 'Hind', sans-serif;
  font-size: 16px !important;
}

.searchFilterContainer .ant-form-item-label {
  padding: 0 !important;
  height: 30px;
  text-align: start;
}

.searchFilterContainer .ant-form-item-row {
  display: flex;
  flex-direction: column;
}

.ant-drawer-mask {
  background: unset !important;
  pointer-events: none !important;
}

/* add lead modal in group report screen css */
.ant-modal {
  top: 0px;
}
.ant-modal-wrap {
  overflow:  hidden !important;
  display: flex;
  align-items: center;
}

.addLeadModal .ant-modal-content {
  height: calc(100vh - 33px);
}

.addLeadModal .ant-modal-body {
  height: calc(100vh - 145px);
}

/* .addLeadModal .ant-table-body {
  height: calc(100vh - 145px);
} */

.addLeadModal {
  top: 16px;
}


