.createNewSourceBody {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.createNewSourceBody label {
    font-size: 15px !important;
    font-weight: 400;
    font-family: 'Mulish', sans-serif;
}

.createNewSourceBody p{
    font-size: 16px;
    font-weight: 400;
}