div.searchFilterForm {
    display: flex;
    gap: 15px;
}

div.buttonContainer {
    display: flex;
    gap: 15px;
}

div.labelWithIcon {
    display: flex;
    gap: 5px;
    cursor: pointer;
}

