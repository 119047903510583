.mainContainer{
    width: 90vw;
    height: 90vh;
    overflow: hidden;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.background{
    width: 101vw;
    height: 101vh;
    position: fixed;
    overflow: hidden;
    z-index: 5;
    background-color: white;

    top: 60px;
    left: 0;
}

.crossButton{
    z-index: 11;
    position: absolute;
    top: 80px;
    right: 10%;
    font-size: 1.3rem;
}

.tableContainer{
    margin: 0 auto;
    height: 100%;
    margin-top: 30px;
}