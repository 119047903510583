div.leadReportBody {
    overflow: auto;
    height: calc(100vh - 116px);
}

.listLeadsSreenbodyheader {
    margin-bottom: 30px;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
}

.leadsListingAction{
    width: 2px;
    height: 2px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1.4px solid #797979;
    cursor: pointer;
}
.listLeadsSreenbodyTable {
    /* overflow: hidden;
    max-height: calc(100vh - 188px); */
    margin-bottom: 30px;
}

div.listLeadsSreenbodyheaderLeft {
    display: flex;
    gap: 20px;
    align-items: center;
}

button.btnThemeSecondary {
    color: #de6834;
    background-color: #FCEBE2 !important;
    width: 40px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
}