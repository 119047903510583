div.fixedFilterDropdownItem {
    padding: 12px 10px;
    cursor: pointer;
    width: 220px;
    height: auto;
    font-size: 16px;
    margin:-11px -12px;
}

div.fixedFilterDropdownItem:hover {
    color: #fff;
    background-color: #de6834;
}