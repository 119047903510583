.mainBody{
    height: 100%;
    width: 100%;
}

.segementContainer{
    display: flex;
    gap: 40px;
    padding-left: 20px;
}

/* DisplayElements CSS */

.displayElement{
    margin: 30px 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(210, 209, 209);
    border-radius: 5px;
}

.innerElements{
    display: flex;
    gap: 40px;
}

.individualElement{
    display: flex;
    margin: 10px 0px;
    margin-right: 50px;
    gap:30px;
    justify-content: space-between;
}

.tabOuter{
    width: 100%;
    display: flex;
    justify-content: center;
}

.tab:hover{
    background-color: #e17748 !important;
    margin: 0 auto;
}

/* Single If Condition Block */

.singleIfConditionBlock{
    display: flex;
    margin: 10px 0px;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

