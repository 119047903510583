.body{
    display: flex;
    width: 100%;
    height: 50px;   
    align-items: center;
    gap:10px
}
.title{
    margin-right: auto;
    font-size: 1.3rem;
}
.selectionContainer{
    marginLeft: 'auto';
    display: flex;
    gap: 20px;
    align-items: center;
}
.subHeaderButton{
    font-size: 1.2rem
}