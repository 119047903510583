div.pageHeader {
    height: 60px;
    width: 100%;
    display: flex;
    border-bottom: 1px solid #f2f2f2;
    box-shadow: rgb(0 0 0 / 4%) 0px 1px 2px;
}

div.pageHeader > button.leftBox {
    width: 60px !important;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    background-color: #FFEEE5;
    border-radius: 0% !important;
}

div.pageHeader > div.middleBox {
    display: flex;
    margin-left: 25px;
    padding-top: 12px;
    flex-direction: column;
}

div.pageHeader > div.middleBox > div.topBox, 
div.pageHeader > div.middleBox > span.batchTitle {
    color: #de6834;
    font-weight: 600;
    font-size: 13px;
    margin-left: 6px;
    font-family: 'Hind', sans-serif;
}

div.pageHeader > div.middleBox > div.bottomBox {
    gap: 15px;
    display: flex;
}

div.pageHeader > div.middleBox > div.bottomBox > button.batchTab {
    color: black;
    padding: 0px 5px;
    text-align: center;
    text-decoration: none !important;
    font-family: 'Hind', sans-serif !important;
    border-radius: unset;
    height: 26px;
    font-size: 16px;
}

div.pageHeader > div.middleBox > div.bottomBox > button.batchTab:hover {
    color: black;
}

div.pageHeader > div.rightBox {
    margin-left: auto;
    width: 80px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.pageHeader > div.rightBox > img {
    width: 30px;
    height: 30px;
    opacity: .09;
}