.mainBody{
    margin: 0px 30px;
    max-height: calc(100vh - 90px);
}

.mainHeading{
    font-weight: 500;
}

.addBtnHolder{
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: 100px;
    right: 100px
}

.usersListingAction{
    width: 2px;
    height: 2px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1.4px solid #797979;
    cursor: pointer;
}

.headingHolder{
    display: flex;
    gap: 40px;
    align-items: center;
    margin-bottom: 30px;
}
