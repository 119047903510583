.container {
    display: flex;
    width: 100vw;
    height:90vh;
}
.leftPart {
    flex-basis: 30%;
    flex-shrink: 0;
}
.rightPart {
    margin-top: 50px;
    flex-basis: 60%;
    padding:0 1rem;
    overflow-y: scroll;
    padding-right: 2px;
    margin-bottom: 2rem;
}
.gridElement {
    /* margin-top:2rem; */
    height: 100%;
	display: grid;
    margin-bottom: 0 !important;
    justify-content: space-between;
    align-items:end;
	grid-template-columns: repeat(auto-fit,200px);
    column-gap: 1rem;
    row-gap: 1.3rem;
    /* column-gap: min(10vw); */
	/* row-gap: 10vh; */
}
.gridElement > label {
    display: flex;
    align-items: center;
    margin-left:0 !important;
    height: fit-content;
    width: fit-content;
} 
.gridElement > input {
    display: flex;
    align-items: center;
   
}

.logo {
    width: 150px;
    height: 150px;
    /* background-color: rgb(170, 68, 68); */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 54px;
}
.cardComponent {
    padding:2rem;
    /* gap: 1.5rem; */
    min-width: 300px;
    /* border: 1px solid black; */
    /* box-shadow: 0 0 5px rgba(0,0,0,0.3); */
    display: flex;
    height: fit-content;
    width: 70%;
    
    margin:50px auto;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}
.cardComponentInner {
    /* box-shadow: 1px 1px 4px rgba(0,0,0,0.1); */
	display: flex;
	flex-direction: column;
	align-items: center;
    height: 100% !important;
    /* width: 220px !important; */
    margin:auto;
    justify-content: space-around;
    gap:1rem;
	
}
.roleName {
    font-size: 24px;
    text-align: center;
}
.button {
    display: flex;
    align-items: center;
    font-size: 14px;
}
.buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1.5rem;
}
.rolesCanBeCreatedSelect{
    width: 100%;
    /* padding: 0 0.5rem; */
   
}
.gridContainer {
   
    margin-bottom: -2px !important;
}