.titleHeading{
    margin-top: 0px;
}

.childElement{
    border: 1px solid rgb(210, 209, 209);
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.childElementTag{
    font-weight: 500;
    margin: 0px;
}

.contentContainer{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid rgb(210, 209, 209);
    border-radius: 5px;
    flex-basis: 33%;
}

.contentContainer > p {
    flex-basis: 33%;
    text-align: center;
}

.container{
    padding: 0px 30px;
}
