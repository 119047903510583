.mainContainer {
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100vh - 60px);
    align-items: center;    
}
body {
    height: 100vh;
}
.row{
    width: 100vw !important;
}
.col {
    display: flex;
    align-items: center;
    /* justify-content: center; */
	padding:0.5rem !important;
	max-height: 400px;
}
.circle {
    position:absolute;
    left:0;
    top:0;
}
.circle1 {
    background-color: #88462a;
    width: 120px;
    height: 120px;
    opacity: 0.4;
    border-radius: 50%;
    position: relative;
    right: 15px;
}
.circle2{
    background-color: #de6834;
    width: 75px;
    height: 90px;
    opacity: 0.4;
    border-radius: 50%;
    position: relative;
    right: 50px;
    top: -56px;
}
.circle3{
	background-color: #e6b9a1;
    width: 161px;
    height: 168px;
    opacity: 0.4;
    border-radius: 50%;
    position: relative;
    right: 68px;
    top: -108px;
}
.image{
	width: 90%;
	
	display: block;
	margin:auto;
}
.link {
	font-size:20px;
	color:#de6834;
}
.link:hover {
	font-size:20px;
	color:#d88764;
}
.text {
	padding-left: 20%;
}
@media screen and (max-width:770px) {
	.image{
		width: 70%;
	}	
	.text {
		padding-left:20%;
	}
}
