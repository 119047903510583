.fullScreenModelMain{
    transition: all 0.3s;
    position: fixed;
    width: 102vw;
    height: 102vh;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 10;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #de6834;
    padding: 20px
}

.fullScreenGraphHolder{
    flex: 1 1;
    flex-direction: column;
    display: flex;
    position: relative;
    margin: 30px 0px;
    height: 90vh;
}