div.pageContent {
    width: 100%;
    overflow-y: auto;
    height: calc(100vh - 117px);
    font-size: 16px;
}

div.pageContent > div.pageContentRow {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto!important;
    margin-right: auto!important;
}

div.pageContent > div.pageContentRow > div.leftPart {
    height: calc(100vh - 117px);
    overflow: auto!important;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

div.pageContent > div.pageContentRow > div.leftPart > div.outerContainer {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin: 1rem auto;
}

div.pageContent > div.pageContentRow > div.leftPart > div.outerContainer > 
div.outerContainerRow {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

div.timelineContainer {
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
}

ul.timeline {
    list-style-type: none;
    position: relative;
    margin: unset;
    padding: unset;
}

ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 24px;
    width: 2px;
    height: 100%;
    z-index: 400;
}

div.startingMonthBox {
    gap: 8px;
    display: flex;
    position: relative;
    left: -125px;
    margin: 10px 0px;
    align-items: center;
    width: 115px;
    justify-content: end;
}

div.startingMonthBox > span.startingMonth {
    top: 2px;
    font-size: 16px;
    color: #de6834;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    font-family: 'Hind', sans-serif;
}

div.monthlyActivity {
    padding-left: 92px;
    margin-left: -96px;
}

ul.timeline li {
    margin: 0px 0px 30px 0px;
    cursor: default;
    padding-left: 20px;
    position: relative;
}

ul.timeline li:before {
    top: 50%;
    left: 15px;
    width: 30px;
    z-index: 400;
    content: ' ';
    cursor: default;
    position: absolute;
    background: white;
    display: inline-block;
    border-bottom: 3px solid #D6D6D6;
}

div.timelineBox {
    display: flex;
    position: relative;
    align-items: center;
}

span.timelineDate {
    left: -112px;
    cursor: default;
    position: absolute;
}

div.pageContent > div.pageContentRow > div.rightPart {
    height: calc(100vh - 117px);
    overflow: auto!important;
    padding-left: 0!important;
    padding-right: 0!important;
    border-left: 1px solid #dee2e6!important;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    position: relative;
    width: 100%;
}

div.studentAddedContainer p {
    padding-left: 20px;
}

div.totalStudentAddedInner, div.activityHeaderInnerContainer {
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 17px;
    font-weight: 500;
}

div.totalStudentAddedInner {
    justify-content: space-between;
}

div.totalStudentAddedInnerContainer {
    display: flex;
    align-items: center;
}

small.createdBy {
    font-size: 89%;
    font-weight: 370;
    margin-left: 1.5rem;
    padding-left: 0.5rem;
}

p.selectedDate {
    color: #de6834;
    font-family: 'Hind', sans-serif;
    font-size: 15px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 10px;
}

p.totalStudentAdded {
    margin-bottom: 0;
    padding: 10px 10px 15px 10px !important;
    border-top: 1px solid #EBEBEB;
    background-color: #FAFAFA !important;
    padding-right: 1rem!important;
}

hr {
    /* margin-top: 0;
    margin-bottom: 0.5rem; */
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

p.studentDetails {
    width: fit-content;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
}

p.noActivity {
    color: #de6834;
    text-align: center!important;
    padding-top: 3rem!important;
    margin-top: 3rem!important;
    margin-bottom: 1rem;
}

div.activityHeader {
    display: flex;
    padding: 10px 20px 10px 10px;
    justify-content: space-between;
    border-top: 1px solid #EBEBEB;
    border-bottom: 1px solid #EBEBEB;
    background-color: #FAFAFA !important;
    margin-bottom: 0.5rem!important;
}

p.activityHeaderInner {
    margin: 0px;
}

img.basicInfoImg, img.statutsUpdateImg {
    margin-right: 0.75rem!important;
    width: 23px;
}

span.activityLabel {
    position: relative;
    bottom: 2px;
}

div.activityBody{
    padding: 10px 20px 5px 10px;
}

div.observerForPagination{
    position: absolute;
    width: 100px;
    height: 400px;
    bottom: 50px;

}

img.polygon {
    transform: rotate(180deg);
}

/* media query */

/* width <= 576px */
@media (min-width: 576px){
    div.pageContent > div.pageContentRow > div.leftPart > div.outerContainer {
        max-width: 540px;
    }
}

/* width <= 768px */
@media (min-width: 768px){
    div.pageContent > div.pageContentRow > div.leftPart > div.outerContainer {
        max-width: 720px;
    }

    div.timelineContainer {
        margin-left: 16.666667%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
}

/* width <= 992px */
@media (min-width: 992px){
    div.pageContent > div.pageContentRow > div.leftPart > div.outerContainer {
        max-width: 960px;
    }
}

/* width <= 1200px */
@media (min-width: 1200px){
    div.pageContent > div.pageContentRow > div.leftPart > div.outerContainer {
        max-width: 1140px;
    }
}
.basicInformationDetails {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
