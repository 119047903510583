div.listSourceSreenbody {
    overflow: auto;
    height: calc(100vh - 55px);
    margin-top: 25px;
    padding-left: 100px;
    padding-right: 100px;
}

.listSourceSreenbodyheader {
    margin-bottom: 30px;
    display: flex;
    gap: 20px;
}

.sourceListingAction{
    width: 2px;
    height: 2px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1.4px solid #797979;
    cursor: pointer;
}

.listSourceSreenbodyTable {
    max-height: calc(100vh - 178px);
}