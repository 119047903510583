.listLeadsSreenbody {
    overflow: auto;
    height: calc(100vh - 55px);
    /* margin-top: 25px; */
    /* padding-left: 100px;
    padding-right: 100px; */
}

.listLeadsSreenbodyheader {
    margin-top: 25px;
    margin-bottom: 30px;
    display: flex;
    gap: 20px;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
}

.leadsListingAction{
    width: 2px;
    height: 2px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1.4px solid #797979;
    cursor: pointer;
}
.listLeadsSreenbodyTable {
    max-height: calc(100vh - 178px);
}

button.btnThemeSecondary {
    color: #de6834;
    background-color: #FCEBE2 !important;
    width: 40px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
}

img.searchImg {
    position: absolute;
    left: 10px;
    top: 6px;
    width: 17px;
    height: 17px;
}

input.leadSearch {
    border: 0;
    border-bottom: 1.2px solid #eeeeee !important;
    outline: none;
    width: 200px;
    padding-right: 20px !important;
    height: 30px;
    border-radius: 0;
    padding-left: 25px;
    margin-left: 8px;
    background-color: transparent;
    font-size: 15px;
    font-family: 'Hind', sans-serif;
}

input.leadSearch::placeholder {
    font-family: 'Hind', sans-serif;
    font-size: 15px;
    color: #b6b6b6;
    padding-top: 10px;
    padding-bottom: 10px;
}

div.bulkAddLeadsBody {
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 16px;
}

label.uploadText {
    color: #de6834;
    font-size: 17px;
    font-weight: 500;
    font-family: 'Avenir', sans-serif;
    margin-bottom: 2px !important;
}