.cardComponent{
    width: 100%;
    height: 120px;
}

.cardComponetCommon{
    color: whitesmoke;
    font-size: 1.1rem;
    font-weight: 400;
}

.cardComponentTitle{
    position: absolute;
    top: 10px;
    left: 10px;
    margin: 0px;
    width: 70%;
}

.cardComponentCount{
    position: absolute;
    bottom: 10px;
    left: 10px;
    margin: 0px;
    font-size: 1.5rem;
}
.cardComponentTrend{
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 0px;

}