div.wrapper,
div.wrapper /deep/ > div.ant-spin-container {
  display: flex;
  height: 100%;
  flex-direction: column;
}

div.wrapper /deep/ > div.ant-spin-blur {
  opacity: 0.05;
}

div.wrapper /deep/ > div > div.ant-spin-lg > div.ant-spin-text {
  font-size: 18px;
}

div.wrapper > div > div.ant-spin-lg > svg.ant-spin-dot {
  font-size: 120px;
  margin: -70px -60px !important;
}